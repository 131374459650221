/* Base styles */
:root {
  --primary: #3662E3;
  --primary-hover: #2a4fb8;
  --primary-light: #EEF4FF;
  --success: #22C55E;
  --warning: #F59E0B;
  --danger: #EF4444;
  --dark: #1E293B;
  --medium: #64748B;
  --light: #94A3B8;
  --lighter: #F1F5F9;
  --white: #FFFFFF;
  --border: #E2E8F0;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.05);
  --radius: 8px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: var(--dark);
  background-color: var(--lighter);
  font-size: 14px;
}

/* App layout */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header styles */
.app-header {
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  box-shadow: var(--shadow-sm);
  padding: 10px 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-icon {
  font-size: 24px;
}

.logo h1 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.main-nav {
  display: flex;
  gap: 8px;
}

.nav-link {
  padding: 8px 16px;
  border-radius: var(--radius);
  color: var(--medium);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s;
}

.nav-link:hover {
  background-color: var(--lighter);
  color: var(--primary);
}

.nav-link.active {
  background-color: var(--primary-light);
  color: var(--primary);
}

.main-content {
  flex: 1;
  padding: 30px 0;
}

/* Page header */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.page-header h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.create-btn {
  background-color: var(--primary);
  color: white;
  padding: 8px 16px;
  border-radius: var(--radius);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
}

.create-btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

/* Empty, loading, error states */
.empty-state, .loading-state, .error-state {
  text-align: center;
  padding: 40px 20px;
  background-color: var(--white);
  border-radius: var(--radius);
  box-shadow: var(--shadow-sm);
  margin-top: 20px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.error-state {
  color: var(--danger);
}

/* Automation list styles */
.automation-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.automation-card {
  background-color: var(--white);
  border-radius: var(--radius);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
  transition: all 0.2s;
}

.automation-card:hover {
  box-shadow: var(--shadow-md);
  transform: translateY(-1px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8fafc;
  border-bottom: 1px solid var(--border);
}

.card-header h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
}

.status-badge.active {
  background-color: rgba(34, 197, 94, 0.15);
  color: var(--success);
}

.status-badge.inactive {
  background-color: rgba(239, 68, 68, 0.15);
  color: var(--danger);
}

.card-main {
  display: flex;
  align-items: center;
  padding: 16px;
}

.trigger-section, .action-section {
  display: flex;
  align-items: center;
  flex: 1;
}

.section-label {
  width: 60px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--medium);
  letter-spacing: 0.5px;
  margin-right: 8px;
}

.trigger-icon, .action-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-light);
  border-radius: 6px;
  margin-right: 12px;
  font-size: 16px;
}

.action-icon {
  background-color: #ECFDF5;
}

.trigger-text, .action-text {
  font-size: 13px;
  color: var(--dark);
  font-weight: 500;
}

.arrow {
  margin: 0 16px;
  color: var(--medium);
  font-size: 18px;
}

.execution-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #f8fafc;
  border-top: 1px solid var(--border);
  font-size: 12px;
  color: var(--medium);
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px 16px;
  border-top: 1px solid #f5f5f5;
}

.deactivate-btn {
  padding: 6px 12px;
  background-color: var(--danger);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.delete-btn {
  padding: 6px 12px;
  background-color: transparent;
  color: var(--medium);
  border: 1px solid var(--border);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.deactivate-btn:hover {
  background-color: #dc2626;
}

.delete-btn:hover {
  background-color: var(--lighter);
}

/* Trigger and Action selector styles for creation screen */
.trigger-grid,
.action-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  margin-bottom: 24px;
}

.trigger-card,
.action-card {
  border: 1px solid var(--border);
  border-radius: var(--radius);
  padding: 12px;
  background-color: var(--white);
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: var(--shadow-sm);
}

.trigger-card:hover,
.action-card:hover {
  border-color: var(--primary);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.trigger-card.selected,
.action-card.selected {
  border-color: var(--primary);
  background-color: var(--primary-light);
  box-shadow: 0 0 0 2px rgba(54, 98, 227, 0.2);
}

.trigger-icon,
.action-icon {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trigger-name,
.action-name {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
  color: var(--dark);
}

.trigger-desc,
.action-desc {
  font-size: 11px;
  color: var(--medium);
  line-height: 1.3;
}

/* Form elements */
.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: var(--dark);
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  font-size: 14px;
  background-color: var(--white);
}

.form-control:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(54, 98, 227, 0.15);
}

/* Buttons */
.btn-primary {
  background-color: var(--primary);
  color: white;
  padding: 10px 16px;
  border-radius: var(--radius);
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.btn-secondary {
  background-color: var(--white);
  color: var(--medium);
  border: 1px solid var(--border);
  padding: 10px 16px;
  border-radius: var(--radius);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-secondary:hover {
  background-color: var(--lighter);
}

/* Creation flow sections */
.creation-step, section h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  gap: 12px;
}

.step-number, section h2:before {
  content: attr(data-step);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  color: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
}

section {
  margin-bottom: 30px;
}

/* Code preview */
.code-preview, pre {
  background-color: #f8fafc;
  border-radius: var(--radius);
  padding: 16px;
  font-family: monospace;
  font-size: 13px;
  overflow-x: auto;
  border: 1px solid var(--border);
}

/* Remove yellow button */
div[style*="background-color: yellow"],
div[style*="background-color:#ffff00"],
div[style*="margin: 30px 0"],
div[style*="backgroundColor: yellow"] {
  display: none !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }
  
  .main-nav {
    margin-top: 12px;
  }
  
  .card-main {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .trigger-section, .action-section {
    width: 100%;
    margin-bottom: 12px;
  }
  
  .arrow {
    margin: 8px 0;
    align-self: center;
    transform: rotate(90deg);
  }
  
  .execution-info {
    flex-direction: column;
    gap: 4px;
  }
  
  .trigger-grid,
  .action-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

/* Settings Page Styles */
.settings-container {
  max-width: 800px;
  margin: 0 auto;
}

.settings-card {
  background-color: var(--white);
  border-radius: var(--radius);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-sm);
  padding: 20px;
  margin-bottom: 24px;
}

.settings-card h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 16px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border);
}

.settings-item {
  margin-bottom: 16px;
}

.settings-label {
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: var(--dark);
}

.settings-value {
  padding: 10px;
  background-color: var(--lighter);
  border-radius: var(--radius);
  word-break: break-all;
}

.settings-value.address {
  font-family: monospace;
  font-size: 13px;
}

/* Automation status styles */
.status-card {
  background-color: var(--white);
  border-radius: var(--radius);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-sm);
  padding: 16px;
  margin-bottom: 20px;
}

.status-card h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border);
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
}

.status-item {
  padding: 8px;
  background-color: var(--lighter);
  border-radius: var(--radius);
}

.status-label {
  font-size: 12px;
  color: var(--medium);
  margin-bottom: 4px;
}

.status-value {
  font-size: 14px;
  font-weight: 600;
}

.status-value.active {
  color: var(--success);
}

.status-value.inactive {
  color: var(--danger);
}

.loading-state.small, .error-state.small {
  padding: 12px;
  margin-bottom: 20px;
}

/* Button styles for Run Now */
.btn-secondary {
  background-color: var(--white);
  color: var(--medium);
  border: 1px solid var(--border);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: var(--lighter);
}

.btn-secondary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}